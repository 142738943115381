<script lang="ts" setup>
const emit = defineEmits(['accept'])

const isShown = defineModel<boolean>()
</script>

<template>
  <v-dialog v-model="isShown" max-width="510">
    <v-card data-cy="freePlanDowngradeDialog">
      <v-card-title class="text-h5"> {{ $t('business.plan.change_to_free') }}? </v-card-title>
      <v-card-text>
        <div class="pb-4">
          <p class="mb-4">{{ $t('dialog.business.change_to_free.text') }}</p>
          <ul>
            <li>{{ $t('dialog.business.downgrade.item_1') }}</li>
            <li>{{ $t('dialog.business.downgrade.item_2') }}</li>
            <li>{{ $t('dialog.business.downgrade.item_3') }}</li>
          </ul>
        </div>
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-btn size="x-large" color="info" variant="outlined" @click="isShown = false">
          {{ $t('global.no_thanks') }}
        </v-btn>
        <v-spacer />
        <v-btn
          size="x-large"
          color="error"
          data-cy="confirmDowngradeFreeBtn"
          variant="elevated"
          @click="emit('accept')"
        >
          {{ $t('business.plan.change_to_free') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
